<template>
    <div class="container">
        <div class="user_reg">
            <el-row :gutter="20">
                <el-col>
                    <div class="right_pannel">
                        <div class="service-query">
                            <div>
                                <div class="sign-top">
                                  <!-- <img src="../../assets/sign-logo-new.png" alt=""> -->
                                </div>
                                <p class="service-prompt">* 您所填写的姓名信息请务必与旅行证件上所列姓名保持一致</p>
                            </div>
                            <div style="width:800px; margin:auto;">
                                <ul class="service-forms">
                                    <li class="form-group">
                                        <label class="form-label">证件类型：</label>
                                        <el-select
                                            id="certificateType"
                                            v-model="registerData.certificateType"
                                            placeholder="请选择"
                                            style="width:350px"
                                        >
                                            <el-option
                                                v-for="item in certTypeOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="certificateNo">证件号：</label>
                                        <el-input
                                            id="certificateNo"
                                            ref="certificateNo"
                                            v-model="registerData.certificateNo"
                                            placeholder="请输入证件号"
                                            maxlength="32"
                                            @blur="idchange()"
                                            style="width:350px"
                                        ></el-input>
                                        <span class="form-required">*</span>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="userTrueName">姓名：</label>
                                        <el-input
                                            id="userTrueName"
                                            ref="userTrueName"
                                            v-model="registerData.name"
                                            placeholder="请输入姓名"
                                            maxlength="15"
                                            style="width:350px"
                                        ></el-input>
                                        <span class="form-required">*</span>
                                    </li>
                                    <li class="form-group" style="display: block">
                                        <label class="form-label" for="certificateNo">称谓：</label>
                                        <div style="width: 350px; display: inline-flex">
                                            <el-radio-group v-model="registerData.sex">
                                                <el-radio style="margin-left: 4rem" label="M">先生</el-radio>
                                                <el-radio style="margin-left: 5rem" label="F">女士</el-radio>
                                            </el-radio-group>
                                        </div>
                                        <span class="form-required">*</span>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="certificateNo">出生日期：</label>
                                        <el-date-picker
                                            v-model="registerData.birthDay"
                                            type="date"
                                            placeholder="请选择出生日期"
                                            style="width:350px"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            :picker-options="pickerOptions"
                                        ></el-date-picker>
                                        <span class="form-required">*</span>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" show-password for="password">密码：</label>
                                        <el-input
                                            id="password"
                                            ref="password"
                                            v-model="registerData.password"
                                            placeholder="请输入密码"
                                            maxlength="16"
                                            style="width:350px"
                                            @input="checkPwd"
                                            autocomplete="new-password"
                                            show-password
                                        ></el-input>
                                        <div class="strength-container">
                                            <div class="strength-bar clearfix">
                                                <div
                                                    :style="pwdStrong>=1?'background-color: #e86868;':'background-color: #cdcdcd;'"
                                                ></div>
                                                <div
                                                    :style="pwdStrong>=2?'background-color:rgb(222, 52, 52);':'background-color: #cdcdcd;'"
                                                ></div>
                                                <div
                                                    :style="pwdStrong>=4?'background-color:rgb(175, 9, 9);':'background-color:#cdcdcd;'"
                                                ></div>
                                            </div>
                                            <p class="strength-text">
                                                <span>弱</span>
                                                <span>中</span>
                                                <span>强</span>
                                            </p>
                                        </div>
                                        <p
                                            style="margin: 0 0 0 100px; padding: 10px 0 0; font-size: 12px; font-weight: 400; line-height: 1; color: #9d9d9d; text-align: left;"
                                        >密码由大小写字母、数字、特殊字符组成的8到16位字符，至少包含字母、数字组合</p>
                                    </li>
                                    <li class="form-group">
                                        <label
                                            class="form-label"
                                            show-password
                                            for="confirmPassword"
                                        >确认密码：</label>
                                        <el-input
                                            id="confirmPassword"
                                            ref="confirmPassword"
                                            v-model="registerData.confirmPassword"
                                            placeholder="请输入确认密码"
                                            autocomplete="new-password"
                                            @blur="changePwd()"
                                            style="width:350px"
                                            show-password
                                        ></el-input>
                                        <span class="form-required">*</span>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="phone">手机号：</label>
                                        <el-input
                                            id="phone"
                                            ref="phone"
                                            v-model="registerData.phone"
                                            @blur="changePhone()"
                                            placeholder="请输入手机号"
                                            style="width:350px"
                                        ></el-input>
                                        <span class="form-required">*</span>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="email">邮箱：</label>
                                        <el-input
                                            id="email"
                                            ref="email"
                                            v-model="registerData.email"
                                            @blur="changeEmail"
                                            placeholder="请输入邮箱"
                                            style="width:350px"
                                        ></el-input>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="area">地址：</label>
                                        <el-cascader
                                            id="area"
                                            v-model="registerData.area"
                                            placeholder="请选择地区"
                                            :props="{ expandTrigger: 'hover' }"
                                            :options="countryData"
                                            :clearable="true"
                                            :filterable="true"
                                            style="width:350px"
                                        ></el-cascader>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="address"></label>
                                        <el-input
                                            id="address"
                                            ref="address"
                                            v-model="registerData.address"
                                            placeholder="请输入详细地址"
                                            style="width:350px"
                                        ></el-input>
                                    </li>
                                    <li class="form-group" style="display: inline-flex">
                                        <label class="form-label" for="picValidateCode">验证码：</label>
                                        <el-input
                                            id="picValidateCode"
                                            :clearable="true"
                                            v-model="registerData.picValidateCode"
                                            ref="picValidateCode"
                                            placeholder="请输入图形验证码"
                                            style="width:220px;height: 40px!important "
                                        ></el-input>
                                        <el-image
                                            style="width: 100px;padding-left: 10px;"
                                            :src="picValidateUrl"
                                            @click="changeValidateCode"
                                        ></el-image>
                                        <div @click="changeValidateCode" class="change-btn">看不清，换一张</div>
                                    </li>
                                    <li class="form-group" style="display:flex;">
                                        <label class="form-label" for="phoneValidateCode">短信验证码：</label>
                                        <el-input
                                            id="phoneValidateCode"
                                            ref="phoneValidateCode"
                                            v-model="registerData.phoneValidateCode"
                                            placeholder="请输入验证码"
                                            style="width:220px"
                                        >
                                            <span class="form-required">*</span>
                                        </el-input>
                                        <el-button
                                            style="margin-left: 10px;"
                                            class="validate-btn"
                                            @click="fetchPhoneValidateCodeHandler"
                                            v-loading="callphoneloading"
                                            :disabled="(phoneCodeIntervalMax<=0 || phoneCodeIntervalMax>=60)?false:true"
                                        >{{phoneValidateCodeLabel}}</el-button>
                                    </li>
                                    <li>
                                        <div
                                            class="checkin-links agree-protocol query"
                                            id="regContainer"
                                        >
                                            <el-checkbox v-model="registerData.checked">已阅读并同意</el-checkbox>
                                            <a
                                                class="link link-sm"
                                                @click="showDialog"
                                                target="_blank"
                                            >《湖南航空用户注册协议》</a>
                                            <a
                                                class="link link-sm"
                                                @click="showPrivacyDialog"
                                                target="_blank"
                                            >《隐私政策》</a>
                                        </div>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label"></label>
                                        <div style="text-align:center;width:100%;display: inline">
                                            <el-button
                                                type="danger"
                                                style="width:300px; margin-bottom: 10px"
                                                @click="registHander"
                                            >注&nbsp;册</el-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-dialog title="湖南航空用户注册协议" :visible.sync="dialogVisible" width="700px">
                <div
                    v-html="regProtocalContent"
                    style="height: 400px;line-height: 25px; overflow-y: scroll;overflow-x:hidden"
                ></div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="dialogVisible = false">关闭</el-button>
                </span>
            </el-dialog>
            <el-dialog title="隐私政策" :visible.sync="dialogPrivacyVisible" width="700px">
                <div
                    v-html="privacyContent"
                    style="height: 400px;line-height: 25px; overflow-y: scroll;overflow-x:hidden"
                ></div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="dialogPrivacyVisible = false">关闭</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import {
  fetchPhoneValidateCode,
  getOneByAliasCode,
  regist
} from "@/api/newsapi";
import countryData from "@/utils/country-level3-data.js";
import Vue from "vue";
import moment from "moment";
import { CURRENT_USER } from "@/store/mutation-types";
import { EventBus } from "@/api/event-bus.js";

export default {
  data() {
    return {
      input: "",
      phoneValidateCodeLabel: "获取验证码",
      phoneCodeIntervalMax: 60,
      pwdStrong: 0,
      pwdOk:false,
      dialogVisible: false,
      dialogPrivacyVisible:false,
      callphoneloading: false,
      regProtocalContent: "",
      privacyContent:'',
      baseValidateUrl: "/api/user/createCaptcha?random=",
      picValidateUrl: "",
      validateCodeRequest: {
        phone: "",
        picValidateCode: "",
        action: "",
        sendType: "register"
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      registerData: {
        sex: "M",
        certificateType: "NI",
        certificateNo: "",
        phone: "",
        name: "",
        email: "",
        birthDay: "",
        password: "",
        confirmPassword: "",
        phoneValidateCode: "",
        picValidateCode: "",
        checked: false
      },
      countryData: countryData,
      certTypeOptions: [
        {
          value: "NI",
          label: "身份证"
        },
        {
          value: "PP",
          label: "护照"
        },
        {
          value: "GM",
          label: "军官证"
        },
        {
          value: "HX",
          label: "回乡证"
        },
        {
          value: "TB",
          label: "台胞证"
        },
        {
          value: "TW",
          label: "台湾通行证"
        },
        {
          value: "HY",
          label: "海员证"
        },
        {
          value: "QT",
          label: "其它"
        }
      ]
    };
  },
  created() {
    this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
  },
  mounted() {
     document.title = this.$route.meta.title;
    getOneByAliasCode({ aliasCode: "user-regist" }).then(res => {
      if (res.success) {
        this.regProtocalContent = res.data.newsContent;
      }
    });
    getOneByAliasCode({ aliasCode: "privacy" }).then(res => {
          if (res.success) {
            this.privacyContent = res.data.newsContent;
          }
        });
  },
  methods: {
    idchange() {
      if (
        this.registerData.certificateType === "NI" &&
        this.registerData.certificateNo.length > 0
      ) {
        var pattern = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!pattern.test(this.registerData.certificateNo)) {
          this.$message.error("证件号不符合规范，请重新输入");
          this.$refs.certificateNo.focus();
        }
      }
      this.registerData.birthDay = this.getBirthdayFromIdCard(
        this.registerData.certificateNo
      );
      this.registerData.sex = this.getsex(this.registerData.certificateNo);
    },
    showDialog() {
      this.dialogVisible = true;
    },
    showPrivacyDialog(){
      this.dialogPrivacyVisible = true;
    },
    getBirthdayFromIdCard: function(idCard) {
      var birthday = "";
      if (idCard != null && idCard != "") {
        if (idCard.length == 15) {
          birthday = "19" + idCard.substr(6, 6);
        } else if (idCard.length == 18) {
          birthday = idCard.substr(6, 8);
        }

        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
        if (moment(birthday) > Date.now()) {
          return "";
        }
      }
      return birthday;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    getsex(idno) {
      var sexno, sex;
      if (idno.length == 18) {
        sexno = idno.substring(16, 17);
      } else if (idno.length == 15) {
        sexno = idno.substring(14, 15);
      } else {
        return;
      }
      var tempid = sexno % 2;
      if (tempid == 0) {
        sex = "F";
      } else {
        sex = "M";
      }

      return sex;
    },
    fetchPhoneValidateCodeHandler() {
      if (this.registerData.phone.length != 11) {
        this.$message.error("手机号码校验不通过，请核实输入11位手机号");
        this.$refs.phone.focus();
        return;
      }
      if (this.registerData.picValidateCode.length < 4) {
        this.$message.error("请先输入图像验证码!");
        this.$refs.picValidateCode.focus();
        return;
      }
      this.validateCodeRequest.phone = this.registerData.phone;
      this.validateCodeRequest.picValidateCode = this.registerData.picValidateCode;
      this.callphoneloading = true;
      fetchPhoneValidateCode(this.validateCodeRequest).then(res => {
        this.callphoneloading = false;
        if (res.success) {
          this.phoneCodeIntevel();
          console.log(res);
        } else {
          this.$message.error(res.errorMessage);
        }
      });
    },
    changePhone() {
      if (this.registerData.phone.length > 0) {
        var pattern = /^[1]([3-9])[0-9]{9}$/;
        if (!pattern.test(this.registerData.phone)) {
          this.$message.error("手机号不符合规范，请重新输入");
          this.$refs.phone.focus();
        }
      }
    },
    changeEmail() {
      if (this.registerData.email.length > 0) {
        var pattern = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (!pattern.test(this.registerData.email)) {
          this.$message.error("邮箱不符合规范，请重新输入");
          this.$refs.email.focus();
        }
      }
    },
    changePwd() {
      if (
        this.registerData.confirmPassword.length > 0 &&
        this.registerData.pwd.length > 0
      ) {
        if (this.registerData.confirmPassword !== this.registerData.pwd) {
          this.$message.error("两次密码输入不一致，请重新输入");
          this.$refs.confirmPassword.focus();
        }
      }
    },
    changeValidateCode() {
      this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
    },
    checkPwd() {
      console.log("aa:" + this.registerData.password);
      if (this.registerData.password.length < 8) {
        this.pwdStrong = 0;
        return;
      }
      let val = this.registerData.password; //获取输入的值
      let numReg = RegExp(/[0-9]/); //判断是否存在数字
      let aaaReg = RegExp(/[a-z]/); //判断是否存在小写字符
      let AAAReg = RegExp(/[A-Z]/); //判断是否存在大写字符
      //正则判断
      let num, aaa, AAA,FFF;
      numReg.test(val) ? (num = true) : (num = false);
      aaaReg.test(val) ? (aaa = true) : (aaa = false);
      AAAReg.test(val) ? (AAA = true) : (AAA = false);

      for (var i = 0; i < val.length; i++) {
            //测试每一个字符的类别并统计一共有多少种模式.
           var fType=this.getCharType(val.charCodeAt(i));
           if(fType==8){
            FFF=true;
           }
      }

      if((num && aaa) || (num && AAA)){
        this.pwdOk=true;
      }

      //将结果做成一个数组
      let result = [num, aaa, AAA,FFF];

      let len = result.length;
      //定义有几种类型
      let type = 0;
      for (let i = 0; i < len; i++) {
        if (result[i]) {
          type++;
        }
      }

      this.pwdStrong = type;
    },
      getCharType(iN) {
      if (iN >= 48 && iN <= 57)
        //数字
        return 1;
      if (iN >= 65 && iN <= 90)
        //大写字母
        return 2;
      if (iN >= 97 && iN <= 122)
        //小写
        return 4;
      else return 8; //特殊字符
    },
    phoneCodeIntevel() {
      var that = this;
      that.phoneCodeIntervalMax = 60;
      var interval = setInterval(function() {
        var remainSecond = that.phoneCodeIntervalMax--;
        if (remainSecond <= 0) {
          clearInterval(interval);
          that.phoneValidateCodeLabel = "获取验证码";
          return;
        }
        that.phoneValidateCodeLabel = remainSecond + "秒后重新获取";
      }, 1000);
    },
    registHander() {
      if (this.registerData.certificateNo.length <= 0) {
        this.$message.error("证件号码不能为空");
        this.$refs.certificateNo.focus();
        return;
      }
      if (this.registerData.name.length <= 0) {
        this.$message.error("姓名不能为空");
        this.$refs.userTrueName.focus();
        return;
      }
      if (this.registerData.birthDay.length <= 0) {
        this.$message.error("生日不能为空");
        this.$refs.password.focus();
        return;
      }
      if (this.registerData.password.length <= 0) {
        this.$message.error("密码不能为空");
        this.$refs.password.focus();
        return;
      }
      if(!this.pwdOk){
        this.$message.error("您的密码太简单，请至少包含数字及字母");
        this.$refs.password.focus();
        return;
      }
      if (this.registerData.password.length < 8 || this.registerData.password.length >16) {
        this.$message.error("密码需要为8-16位字符，请核实重新输入");
        this.$refs.password.focus();
        return;
      }
      if (this.registerData.password !== this.registerData.confirmPassword) {
        this.$message.error("两次密码输入不一致，请重新输入");
        this.$refs.confirmPassword.focus();
        return;
      }
      if (this.registerData.phone.length != 11) {
        this.$message.error("手机号码输入错误，请重新输入");
        this.$refs.phone.focus();
        return;
      }
      if (this.registerData.phoneValidateCode.length != 6) {
        this.$message.error("短信验证码输入不正确,请重新输入");
        this.$refs.phoneValidateCode.focus();
        return;
      }
      if (!this.registerData.checked) {
        this.$message.error("请先阅读湖南航空会员注册协议！");
        this.$refs.phoneValidateCode.focus();
        return;
      }
      regist(this.registerData).then(res => {
        if (res.success) {
          this.$alert("恭喜你注册成功", "注册提示", {
            confirmButtonText: "确定",
            callback: action => {
              Vue.ls.set(CURRENT_USER, res.data);
              EventBus.$emit("onlogin", "");
              this.$router.push({
                path: "/member/orderlist",
                query: { t: new Date().getTime() }
              });
              // this.$router.push({path:'/login',query: {t:new Date().getTime()}})
            }
          });
        } else {
          this.$message.error("注册失败," + res.errorMessage);
          this.changeValidateCode();
        }
      });
    }
  }
};
</script>
<style scoped lang='less'>
.user_reg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.right_pannel {
  position: relative;
  background-color: #fff;
  border: 1px solid #ededed;
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  /* color: #337ab7; */
  text-decoration: underline;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-query .service-forms > li {
  margin-top: 20px;
}

.service-forms {
  padding-left: 7rem;
}

.form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.form-group .form-required {
  margin-left: 8px;
  font-size: 26px;
  font-weight: 700;
  padding-top: 4px;
  color: #e34c4c;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
}

.strength-container {
  position: relative;
  top: 5px;
  display: inline-block;
  margin-left: 16px;
  width: 192px;
  vertical-align: middle;
}

.strength-container .strength-bar {
  display: block;
  padding: 2px;
  height: 9px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
}

.strength-container .strength-bar div {
  display: block;
  float: left;
  width: 60px;
  height: 8px;
  background-color: #cdcdcd;
}

.strength-container .strength-bar div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.strength-container .strength-bar div:nth-child(2) {
  margin: 0 3px;
}

.strength-container .strength-bar div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.strength-container .strength-text {
  padding: 4px 0 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: #4d4e4e;
  text-align: center;
}

.strength-container .strength-text span {
  display: inline-block;
  width: 33.33%;
}

.strength-container.weak > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.strong > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:last-child {
  background-color: #6aa001;
}

#regContainer .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ff103a;
  border-color: #ff103a;
}

#regContainer .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff103a;
}

#regContainer .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff103a;
}

.clearfix {
  display: block;
  height: 0px;
  content: "";
  clear: both;
  visibility: inherit;
  overflow: hidden;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* color: #2c6e8b; */
  text-align: left;
}
.sign-top {
  width: 100%;
  height: 200px;
  // img{
  //   width: 100%;
  //   height: 200px;
  // }
  background: url("../../assets/sign-logo.png") 100% 100%; 
}
.validate-btn {
  width: 100px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d70039;
  cursor: pointer;
  border-radius: 5px;
  background: #ffffff;
  color: #4d4e4e;
}
.change-btn {
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
  color: #009ed0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
